const LIBRARY = [
  {
    id: 1,
    title: "Kotlin Design Patterns and Best Practices",
    date: Date.parse("January 2, 2024"),
    tags: ["Kotlin", "Books"],
    url: "https://www.amazon.in/Kotlin-Design-Patterns-Best-Practices/dp/1801815720",
    description:
      "Build scalable applications using traditional, reactive, and concurrent design patterns in Kotlin",
  },
  {
    id: 2,
    title: "Go Class",
    date: Date.parse("November 23, 2023"),
    tags: ["Go", "Youtube"],
    url: "https://www.youtube.com/watch?v=iDQAZEJK8lI&list=PLoILbKo9rG3skRCj37Kn5Zj803hhiuRK6",
    description: "A complete guide to the Go programming language",
  },
  {
    id: 3,
    title: "Why thread pool even exists?",
    date: Date.parse("Febuary 12, 2024"),
    tags: ["Threads", "Youtube"],
    url: "https://youtu.be/NgYS6mIUYmA?si=jBC0ZnYzaRzOXO5s",
    description: "A deep dive into the concept of thread pool",
  },
  {
    id: 4,
    title: '"ANOTHER WORLD" CODE REVIEW',
    date: Date.parse("March 18, 2024"),
    tags: ["Memory Management", "CPU Architecture", "Virtual Machine"],
    url: "https://fabiensanglard.net/anotherWorld_code_review/",
    description:
      "A good read on how 'Another world' game's architecture was built to run wihtin 600KB of RAM",
  },
  {
    id: 5,
    title: "Most tech content is bullshit",
    date: Date.parse("April 2, 2024"),
    tags: [],
    url: "https://www.aleksandra.codes/tech-content-consumer",
    description:
      "How developers are consuming content without questioning its credibility and suffering from the Halo Effect.",
  },
];

export default LIBRARY;
