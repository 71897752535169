import React from "react";
import { useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import CodeTheme from "./CodeTheme";
import copyIcon from "../../assets/copyicon.png";
import copied from "../../assets/copied.png";

const CodeBlock = ({ className, children }) => {
  const language = className ? className.split("-")[1] : "";

  const [isHovered, setIsHovered] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(children).then(
      () => {
        setIsCopied(true);
      },
      (err) => {}
    );
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <figure
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        display: "grid",
        position: "relative",
        marginInlineStart: "0px",
        marginInlineEnd: "0px",
      }}
    >
      <SyntaxHighlighter
        language={language}
        style={CodeTheme}
        showLineNumbers={true}
      >
        {children}
      </SyntaxHighlighter>
      {isHovered && (
        <div
          onClick={handleCopy}
          style={{
            position: "absolute",
            top: "2rem",
            right: "1rem",
            cursor: "pointer",
            background: "#222222",
            padding: "0.2rem 0.2rem 0rem 0.2rem",
            borderRadius: "5px",
            border: `1px solid ${isCopied ? "#00ba02" : "#d4d4d4"}`,
          }}
        >
          <img
            src={isCopied ? copied : copyIcon}
            alt="copy"
            style={{
              filter: `${isCopied ? "" : "invert(100%)"}`,
              width: "20px",
              height: "18px",
            }}
          />
        </div>
      )}
    </figure>
  );
};

export default CodeBlock;
