import styles from "./About.module.css";
import NavBar from "../nav-bar/NavBar";
import commonStyle from "../../common.module.css";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <div className={commonStyle.container}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Aayush Chaudhary</title>
        <meta
          name="description"
          content="Hi, I'm Aayush, a software engineer who enjoys tinkering with
              Android apps. Currently, I am working at ShareChat."
        />
        <meta name="author" content="Aayush Chaudhary" />
        <meta
          name="keywords"
          content="aayush, aayush chaudhary, android developer"
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="googlebot"
          content="index, follow, max-video-preview:-1, max-image-preview:large, max-snippet:-1"
        />
      </Helmet>
      <NavBar />
      <div className={commonStyle[`main-content`]}>
        <h1 className={commonStyle[`blog-list-title`]}>About</h1>
        <div className={commonStyle.divider} />
        <p className={styles.detail}>
          I am Aayush, an Android engineer passionate about turning complex
          problems into user-friendly solutions. Currently, I am working at
          ShareChat in its live team.
        </p>
        <p className={styles.detail}>
          At{" "}
          <a
            href="https://play.google.com/store/apps/details?id=in.mohalla.sharechat&hl=en&gl=US&pli=1"
            target="_blank"
            rel="noopener noreferrer"
          >
            ShareChat
          </a>
          , I focus on enhancing the Virtual Gifting experience in Audio
          Chatrooms, making social interactions more engaging for millions of
          users. ShareChat is a social media app built to cater to Indian users
          in their regional languages. As part of the Gifter gratification team,
          I get to work on some really cool UIs and animations. It's really
          overwhelming to work on something used by millions of people.
        </p>
        <p className={styles.detail}>
          I'll be sharing Android-related stuff{" "}
          <a href="/blogs" target="_blank" rel="noopener noreferrer">
            here
          </a>
          , so stay tuned. Also, I've recently developed a keen interest in
          learning Go and databases, so I'll be sharing my learnings about
          these, apart from Android.
        </p>
        <p className={styles.detail}>
          But it's not all about tech! I'm also passionate about delving into
          life experiences and learning from them. If you're interested in
          personal development-related content, you can check out some pieces
          I've{" "}
          <a href="/reflections" target="_blank" rel="noopener noreferrer">
            written
          </a>{" "}
          on life.
        </p>
        <p className={styles.detail}>
          As part of this journey, I hope to provide valuable content that not
          only caters to developers but also offers a glimpse into my personal
          experiences.
        </p>
        <p className={styles.detail}>
          You can reach me through{" "}
          <a
            target="_blank"
            aria-label="Mail"
            href="mailto:aayushchaudhary287@gmail.com"
            rel="noopener noreferrer"
          >
            email
          </a>
          , but if you want a quick reply, then drop a hi on{" "}
          <a
            target="_blank"
            aria-label="Twitter"
            href="https://twitter.com/thearchetypee"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default About;
