import {
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import "./App.css";
import Home from "./components/home/Home";
import RootLayout from "./root/Root";
import ReadingList from "./components/readinglist/ReadingList";
import BlogList from "./components/blogs/BlogList";
import About from "./components/about/About";
import ErrorPage from "./components/error/ErrorPage";
import BlogDetail from "./components/blog-renderer/BlogDetail";
import ContemplationsList from "./components/contemplations/ContemplationsList";


const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/about", element: <About /> },
      { path: "/library", element: <ReadingList /> },
      { path: "/:type/:path", element: <BlogDetail /> },
      { path: "/blogs", element: <BlogList /> },
      { path: "/reflections", element: <ContemplationsList /> },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
