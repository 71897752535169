import style from "./Link.module.css";

const CustomLink = ({ href, children }) => {
    return (
      <a
        href={href}
        className={style.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
};

export default CustomLink;