import React from "react";
import style from "./Gif.module.css";

const GifComponent = ({ src, alt, height }) => {
  return (
    <img className={style[`rounded-gif`]} src={src} alt={alt} height={height} />
  );
};

export default GifComponent;
