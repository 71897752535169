import React, { useRef, useState } from "react";
import style from "./CustomVideo.module.css";
import play from "../../../assets/play.svg";
import pause from "../../../assets/pause.svg";

const CustomVideo = ({ src, width, height }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);

  const togglePlayPause = () => {
    const video = videoRef.current;
    if (video.paused || video.ended) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  return (
    <div className={style[`video-container`]}>
      <video autoPlay muted loop ref={videoRef} width={width} height={height}>
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className={style[`video-controls`]}>
        <img
          src={isPlaying ? pause : play}
          onClick={togglePlayPause}
          alt="play-pause"
        ></img>
      </div>
    </div>
  );
};

export default CustomVideo;
