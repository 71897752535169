import styles from "./Socials.module.css";
import githubicon from "../../assets/githubicon.svg";
import twittericon from "../../assets/twittericon.svg";
import linkedinicon from "../../assets/linkedinlogo.svg";
import mail from "../../assets/MailLogo.svg";
import medium from "../../assets/MediumLogo.svg";

const Socials = () => {
  return (
    <>
      <div className={styles.socials}>
        <a
          target="_blank"
          aria-label="Github"
          href="https://github.com/thearchetypee"
          rel="noopener noreferrer"
        >
          <img src={githubicon} alt="Github" className={styles.socialsicons} />
        </a>
        <div className={styles.divider} />
        <a
          target="_blank"
          aria-label="Twitter"
          href="https://twitter.com/thearchetypee"
          rel="noopener noreferrer"
        >
          <img
            src={twittericon}
            alt="Twitter"
            className={styles.socialsicons}
          />
        </a>
        <div className={styles.divider} />
        <a
          target="_blank"
          aria-label="Medium"
          href="https://medium.com/@caayush"
          rel="noopener noreferrer"
        >
          <img src={medium} alt="Medium" className={styles.socialsicons} />
        </a>
        <div className={styles.divider} />
        <a
          target="_blank"
          aria-label="Linkedin"
          href="https://www.linkedin.com/in/aayush-chaudhary/"
          rel="noopener noreferrer"
        >
          <img
            src={linkedinicon}
            alt="Linkedin"
            className={styles.socialsicons}
          />
        </a>
        <div className={styles.divider} />
        <a
          target="_blank"
          aria-label="Mail"
          href="mailto:aayushchaudhary287@gmail.com"
          rel="noopener noreferrer"
        >
          <img src={mail} alt="Mail" className={styles.socialsicons} />
        </a>
      </div>
    </>
  );
};

export default Socials;
