import styles from "./ReadingListItem.module.css";
import formatDate from "../../utils/formatDate";

const ReadingListItem = (props) => {
  var library = props.library;
  return (
    <a
      href={library.url}
      rel="noopener noreferrer"
      target="_blank"
      className={styles.post}
    >
      <h3>{library.title}</h3>
      <p>{library.description}</p>
      <p className={styles[`post-meta`]}>
        <span className={styles[`post-link`]}>{formatDate(library.date)}</span>
        {library.tags.length > 0 && <span className={styles.divider}> • </span>}
        {library.tags.map((tag, index) => (
          <span key={`item_${index}`}>
            <span className={styles[`post-link`]}>{tag}</span>
            {index !== library.tags.length - 1 && (
              <span className={styles[`post-link`]}> • </span>
            )}
          </span>
        ))}
      </p>
    </a>
  );
};

export default ReadingListItem;
