import style from "./BlogListItem.module.css";
import { Link } from "react-router-dom";
import formatDate from "../../utils/formatDate";

const BlogListItem = ({ type, blog }) => {
  return (
    <Link to={`/${type}/${blog.path}`} className={style.post}>
      <h3>{blog.title}</h3>
      <p>{blog.description}</p>
      <p className={style[`post-meta`]}>
        <span className={style[`post-link`]}>{formatDate(blog.date)}</span>
        {blog.tags.length > 0 && <span className={style.divider}> • </span>}
        {blog.tags.map((tag, index) => (
          <span key={`item_${index}`}>
            <span className={style[`post-link`]}>{tag}</span>
            {index !== blog.tags.length - 1 && (
              <span className={style[`post-link`]}> {"  "} • </span>
            )}
          </span>
        ))}
      </p>
    </Link>
  );
};

export default BlogListItem;
