import React from "react";
import commonstlyes from "../../common.module.css";
import { useEffect, useState } from "react";
import Markdown from "markdown-to-jsx";
import CodeBlock from "./CodeBlock.js";
import InlineCode from "./InlineCode.js";
import RoundedImage from "./RoundedImage/RoundedImage.js";
import GifComponent from "./Gif/Gif.js";
import Quote from "./Quote/Quote.js";
import CustomLink from "./Link/Link.js";
import CustomVideo from "./video/CustomVideo.js";
import useScrollToTop from "../../effects/useScrollToTop.js";
import { useParams } from "react-router-dom";
import SideBar from "../nav-bar/SideBar.js";
import { Helmet } from "react-helmet-async";
import ALL_BLOGS from "../../list-assets/Blogs";
import REFLECTIONS from "../../list-assets/Reflections";
import CallOut from "./Callout/CallOut.js";

const BlogDetail = () => {
  useScrollToTop();
  const { type, path } = useParams();
  const [blogContent, setBlogs] = useState("");
  const [blogDetails, setBlogDetails] = useState({});

  useEffect(() => {
    import(`../../blogs-assets/${type}/${path}.mdx`).then((blog) => {
      fetch(blog.default)
        .then((response) => response.text())
        .then((text) => setBlogs(text));
    });
    if (type === "blogs") {
      const blog = ALL_BLOGS.find((blog) => blog.path === path);
      setBlogDetails(blog);
    }
    if (type === "reflections") {
      const blog = REFLECTIONS.find((blog) => blog.path === path);
      setBlogDetails(blog);
    }
  }, [type, path]);
  return (
    <div className={commonstlyes.container}>
      <Helmet>
        <title>{blogDetails.title}</title>
        <meta name="description" content={blogDetails.description} />
        <meta name="author" content="Aayush Chaudhary" />
        <meta name="keywords" content={blogDetails.keywords} />
        <meta name="robots" content="index, follow" />
        <meta
          name="googlebot"
          content="index, follow, max-video-preview:-1, max-image-preview:large, max-snippet:-1"
        />
        <meta property="og:title" content={blogDetails.title} />
        <meta property="og:description" content={blogDetails.description} />
        <meta
          property="og:image"
          content={`https://www.aayushchaudhary.in/${blogDetails.social_image}`}
        />
        <meta property="og:url" content="https://www.aayushchaudhary.in" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@thearchetypee" />
        <meta name="twitter:title" content={blogDetails.title} />
        <meta name="twitter:description" content={blogDetails.description} />
        <meta
          name="twitter:image"
          content={`https://www.aayushchaudhary.in/${blogDetails.social_image}`}
        />
      </Helmet>
      <SideBar type={type} path={path} />
      <div
        className={`${commonstlyes["blog-detail"]} ${commonstlyes["main-content"]}`}
      >
        <Markdown
          options={{
            overrides: {
              code: {
                component: CodeBlock,
              },
              inlinecode: {
                component: InlineCode,
              },
              img: {
                component: RoundedImage,
              },
              gif: {
                component: GifComponent,
              },
              blockquote: {
                component: Quote,
              },
              a: {
                component: CustomLink,
              },
              customvideo: {
                component: CustomVideo,
              },
              aside: {
                component: CallOut,
              },
            },
          }}
        >
          {blogContent}
        </Markdown>
      </div>
    </div>
  );
};

export default BlogDetail;
