import { Outlet } from "react-router-dom";
import styles from "./Root.module.css";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Footer from "../components/footer/Footer";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const RootLayout = () => {
  const location = useLocation();
  useEffect(() => {
    const isGAEnabled = process.env.NODE_ENV === "production";
    if (isGAEnabled) {
      logEvent(analytics, location.pathname);
    } else {
      console.log("Google Analytics is disabled in development mode");
    }
  }, [location]);
  return (
    <HelmetProvider>
      <div className={styles.layout}>
        <main>
          <Outlet />
          <Footer />
        </main>
      </div>
    </HelmetProvider>
  );
};

export default RootLayout;
