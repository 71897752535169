// Purpose: Format date to be displayed in a human-readable format.
const formatDate = (date)  => {
    return new Date(date).toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
    });
};

export default formatDate;
