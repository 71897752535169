import { NavLink } from "react-router-dom";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import Socials from "../footer/Socials";
import styles from "../../common.module.css";
import chevronIcon from "../../assets/right-chev-icon.svg";
import _ from "lodash";

const pathMap = {
  "/": "home",
  "/blogs": "blogs",
  "/library": "library",
  "/reflections": "reflections",
  "/about": "about me",
};

const NavBar = () => {
  const [showNavBar, setShowNavBar] = useState(false);
  const location = useLocation();
  return (
    <nav className={styles.navbar}>
      <div
        className={styles[`nav-bar-hidden-title`]}
        onClick={() => setShowNavBar(!showNavBar)}
      >
        <p>
          {_.isNil(pathMap[location.pathname]) ||
          _.isEmpty(pathMap[location.pathname])
            ? "Error"
            : pathMap[location.pathname]}
        </p>
        <img
          src={chevronIcon}
          alt="chevron"
          width={18}
          height={16}
          className={showNavBar ? styles["rotate"] : ""}
        />
      </div>
      <div
        className={`${styles[`nav-items`]} ${
          showNavBar ? styles.active : styles.inactive
        }`}
      >
        <NavLink
          className={({ isActive }) => (isActive ? styles.active : undefined)}
          to="/"
        >
          home
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? styles.active : undefined)}
          to="/blogs"
        >
          blogs
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? styles.active : undefined)}
          to="/library"
        >
          library
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? styles.active : undefined)}
          to="/reflections"
        >
          reflections
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? styles.active : undefined)}
          to="/about"
        >
          about me
        </NavLink>
        <Socials />
        <p className={styles.copyright}>© 2024</p>
      </div>
    </nav>
  );
};

export default NavBar;
