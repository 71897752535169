import NavBar from "../nav-bar/NavBar";
import commonStyle from "../../common.module.css";
import style from "./ErrorPage.module.css";
import { Helmet } from "react-helmet";

const ErrorPage = () => {
  return (
    <div className={commonStyle.container}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Aayush Chaudhary</title>
        <meta
          name="description"
          content="Hi, I'm Aayush, a software engineer who enjoys tinkering with
              Android apps. Currently, I am working at ShareChat."
        />
        <meta name="author" content="Aayush Chaudhary" />
        <meta
          name="keywords"
          content="aayush, aayush chaudhary, android developer"
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="googlebot"
          content="index, follow, max-video-preview:-1, max-image-preview:large, max-snippet:-1"
        />
      </Helmet>
      <NavBar />
      <div className={commonStyle[`main-content`]}>
        <h1>You lost, buddy!</h1>
        <p>
          Go to{" "}
          <a className={style.link} href="/">
            home
          </a>{" "}
          page.
        </p>
      </div>
    </div>
  );
};

export default ErrorPage;
