import styles from "./ReadingList.module.css";
import NavBar from "../nav-bar/NavBar";
import LIBRARY from "../../list-assets/Library";
import ReadingListItem from "./ReadingListItem";
import commonStyle from "../../common.module.css";
import { Helmet } from "react-helmet";

const ReadingList = () => {
  const library = LIBRARY.toSorted((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });

  return (
    <div className={commonStyle.container}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Library</title>
        <meta
          name="description"
          content="This compilation features books, articles, and lectures that I am
          currently exploring or have delved into previously. I found these
          resources to be valuable, and I hope you will too."
        />
        <meta name="author" content="Aayush Chaudhary" />
        <meta
          name="keywords"
          content="aayush, aayush chaudhary, android developer"
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="googlebot"
          content="index, follow, max-video-preview:-1, max-image-preview:large, max-snippet:-1"
        />
      </Helmet>
      <NavBar />
      <ul className={commonStyle[`main-content`]}>
        <h1 className={commonStyle[`blog-list-title`]}>Library</h1>
        <p className={commonStyle[`blog-list-description`]}>
          This compilation features books, articles, and lectures that I am
          currently exploring or have delved into previously. I found these
          resources to be valuable, and I hope you will too.
        </p>
        {library.map((library, index) => (
          <div key={library.id}>
            <ReadingListItem library={library} />

            {index !== LIBRARY.length - 1 && <div className={styles.divider} />}

            {index === LIBRARY.length - 1 && (
              <p className={styles[`finished-message`]}>That's all folks!</p>
            )}
          </div>
        ))}
      </ul>
    </div>
  );
};

export default ReadingList;
