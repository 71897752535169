import NavBar from "../nav-bar/NavBar";
import commonStyle from "../../common.module.css";
import BlogListItem from "../blog-list-item/BlogListItem";
import REFLECTIONS from "../../list-assets/Reflections";
import { Helmet } from "react-helmet";

const ContemplationsList = () => {
   const blogs = REFLECTIONS.toSorted((a, b) => {
     return new Date(b.date) - new Date(a.date);
   });
  return (
    <div className={commonStyle.container}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Reflections</title>
        <meta
          name="description"
          content="Sharing my thoughts and experiences on life, and everything in
          between."
        />
        <meta name="author" content="Aayush Chaudhary" />
        <meta
          name="keywords"
          content="aayush, aayush chaudhary, android developer, reflections, life, experiences, thoughts"
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="googlebot"
          content="index, follow, max-video-preview:-1, max-image-preview:large, max-snippet:-1"
        />
      </Helmet>
      <NavBar />
      <div className={commonStyle[`main-content`]}>
        <h1 className={commonStyle[`blog-list-title`]}>Reflections</h1>
        <p className={commonStyle[`blog-list-description`]}>
          Sharing my thoughts and experiences on life, and everything in
          between.
        </p>
        {blogs.map((blog, index) => (
          <div key={blog.id}>
            <BlogListItem type="reflections" blog={blog} />
            {index !== blogs.length - 1 && (
              <div className={commonStyle.divider} />
            )}
            {index === blogs.length - 1 && (
              <p className={commonStyle[`finished-message`]}>
                That's all folks!
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContemplationsList;
