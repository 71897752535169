import React from "react";
import style from "./Quote.module.css";

const Quote = ({ children }) => {
  return (
    <div className={style[`quote-container`]}>
      {children}
    </div>
  );
};

export default Quote;
