import React from "react";
import style from "./RoundedImage.module.css";
const RoundedImage = ({ src, alt, height, width, children }) => {
  return (
    <div className={style[`rounded-image-container`]}>
      <img
        className={style[`rounded-image`]}
        src={src}
        alt={alt}
        height={height}
        width={width}
      />
      {children && children.length >= 1 && children[0].props.children}
    </div>
  );
};

export default RoundedImage;
