import styles from "./Footer.module.css";
import Socials from "./Socials";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <nav className={styles.navbar}>
      <div className={styles.divider} />
      <div className={styles[`nav-items`]}>
        <NavLink
          className={({ isActive }) => (isActive ? styles.active : undefined)}
          to="/"
        >
          home
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? styles.active : undefined)}
          to="/blogs"
        >
          blogs
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? styles.active : undefined)}
          to="/library"
        >
          library
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? styles.active : undefined)}
          to="/reflections"
        >
          reflections
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? styles.active : undefined)}
          to="/about"
        >
          about me
        </NavLink>
        <Socials />
        <p className={styles.copyright}>© 2024</p>
      </div>
    </nav>
  );
};

export default Footer;
