import style from "./Home.module.css";
import NavBar from "../nav-bar/NavBar";
import BlogListItem from "../blog-list-item/BlogListItem";
import commonStyle from "../../common.module.css";
import personallogo from "../../assets/personal-logo-black-without-padding.svg";
import ALL_BLOGS from "../../list-assets/Blogs";
import REFLECTIONS from "../../list-assets/Reflections";
import { Helmet } from "react-helmet";

const Home = () => {
  const blogs = ALL_BLOGS.toSorted((a, b) => {
    return new Date(b.date) - new Date(a.date);
  }).slice(0, 3);

  const reflections = REFLECTIONS.toSorted((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });

  return (
    <div className={commonStyle.container}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Aayush Chaudhary</title>
        <meta
          name="description"
          content="Hi, I'm Aayush, a software engineer who enjoys tinkering with
              Android apps. Currently, I am working at ShareChat."
        />
        <meta name="author" content="Aayush Chaudhary" />
        <meta
          name="keywords"
          content="aayush, aayush chaudhary, android developer"
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="googlebot"
          content="index, follow, max-video-preview:-1, max-image-preview:large, max-snippet:-1"
        />
      </Helmet>
      <NavBar />
      <main className={commonStyle[`main-content`]}>
        <section className={style.intro}>
          <div>
            <img
              src={personallogo}
              alt="personal-logo"
              width="52"
              height="52"
            />
            <p>
              Hi, I'm Aayush, a software engineer who enjoys tinkering with
              Android apps. Currently, I am working at ShareChat.
            </p>
          </div>
        </section>
        <section className={style.writing}>
          <div className={style[`list-title`]}>
            <div className={style[`list-title-section`]}>
              <h2>blogs</h2>
            </div>
            <div className={style[`all-link-section`]}>
              <a href="/blogs" className={style[`all-link`]}>
                all →
              </a>
            </div>
          </div>
          {blogs.map((blog, index) => (
            <div key={blog.id}>
              <BlogListItem type="blogs" blog={blog} />
              {index !== blogs.length - 1 && (
                <div className={commonStyle.divider} />
              )}
            </div>
          ))}
        </section>

        <section className={style.reflection}>
          <div className={style[`list-title`]}>
            <div className={style[`list-title-section`]}>
              <h2>reflections</h2>
            </div>
            <div className={style[`all-link-section`]}>
              <a href="/reflections" className={style[`all-link`]}>
                all →
              </a>
            </div>
          </div>
          {reflections.map((reflection, index) => (
            <div key={reflection.id}>
              <BlogListItem type="reflections" blog={reflection} />
              {index !== reflections.length - 1 && (
                <div className={commonStyle.divider} />
              )}
            </div>
          ))}
        </section>
      </main>
    </div>
  );
};

export default Home;
