import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import backButton from "../../assets/backbutton.svg";
import ALL_BLOGS from "../../list-assets/Blogs";
import formatDate from "../../utils/formatDate";
import Socials from "../footer/Socials";

import style from "../../common.module.css";
import REFLECTIONS from "../../list-assets/Reflections";

const SideBar = ({ type, path }) => {
  const navigate = useNavigate();
  const [blogDetails, setBlogDetails] = useState({});

  useEffect(() => {
    if (type === "blogs") {
      const blog = ALL_BLOGS.find((blog) => blog.path === path);
      setBlogDetails(blog);
    }
    if (type === "reflections") {
      const blog = REFLECTIONS.find((blog) => blog.path === path);
      setBlogDetails(blog);
    }
  }, [type, path]);

  const goBackOrHome = () => {
    if (type === "blogs") {
      navigate("/blogs");
    } else if (type === "reflections") {
      navigate("/reflections");
    } else {
      navigate("/");
    }
  };

  return (
    <nav className={style.sidebar}>
      <div className={style[`sidebar-small`]}>
        <img
          src={backButton}
          alt="back"
          onClick={goBackOrHome}
          className={style["back-button-small"]}
        />
        <p className={style["published-date-small"]}>
          {formatDate(blogDetails.date)}
        </p>
      </div>
      <div className={style["nav-items"]}>
        <img
          src={backButton}
          alt="back"
          onClick={goBackOrHome}
          className={style["back-button"]}
        />
        <p className={style["published-date-title"]}>Published on:</p>
        <p className={style["published-date"]}>
          {formatDate(blogDetails.date)}
        </p>
        <Socials />
        <p className={style["copyright"]}>© 2024</p>
      </div>
    </nav>
  );
};

export default SideBar;
