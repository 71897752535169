const ALL_BLOGS = [
  {
    id: 10,
    title: "Room Tutorial(Part-4): Migrating Database",
    date: Date.parse("June 20, 2024"),
    tags: ["Android", "Room Database"],
    description:
      "Learn database migration in Room for Android. Explore manual migrations, auto migrations, and handling schema changes in your app's database.",
    path: "room-tutorial-part-4",
    slug: "/blogs/room-tutorial-part-4",
    social_image: "/assets/blogs/room-tutorial/social-image.jpg",
    keywords:
      "Room Database migration, Android database schema updates, Room auto migration, Manual database migration Android, Room migration strategy, Android app database versioning, Room @AutoMigration annotation, Android database schema changes, Room Database tutorial, Kotlin Room implementation, Android data persistence, Room Database components, Android database optimization, Room migration SQL, Android app development, Room Database version control, Android database best practices, Room SupportSQLiteDatabase, Room AutoMigrationSpec, Android database renaming tables",
  },
  {
    id: 9,
    title: "Room Tutorial(Part-3): Define Relationships Between Tables",
    date: Date.parse("June 15, 2024"),
    tags: ["Android", "Room Database"],
    description:
      "Explore defining relationships in Room Database for Android. Learn one-to-one, one-to-many, and many-to-many relationships using intermediate data classes.",
    path: "room-tutorial-part-3",
    slug: "/blogs/room-tutorial-part-3",
    social_image: "/assets/blogs/room-tutorial/social-image.jpg",
    keywords:
      "Room Database relationships, Android ORM, Room Entity relationships, One-to-one relationship Room, One-to-many relationship Room, Many-to-many relationship Room, Room Database tutorial, Android data modeling, Room @Embedded annotation, Room @Relation annotation, Android database foreign keys, Room junction tables, Android app development, Room Database queries, Kotlin Room implementation, Android data persistence, Room Database components, Android database optimization, Room @Transaction annotation, Android database best practices",
  },
  {
    id: 8,
    title: "Room Tutorial(Part-2): Insert and Access Data",
    date: Date.parse("June 10, 2024"),
    tags: ["Android", "Room Database"],
    description:
      "Learn to create entities and DAOs in Room Database for Android. Discover how to insert, access, and query data asynchronously.",
    path: "room-tutorial-part-2",
    slug: "/blogs/room-tutorial-part-2",
    social_image: "/assets/blogs/room-tutorial/social-image.jpg",
    keywords:
      "Room Database entities, Android DAO, Room data insertion, Room data access, Room Database queries, Android data persistence, Room asynchronous queries, Kotlin Room implementation, Room Database setup, Android local database, Room Entity annotation, Room Database components, Android ORM, Room Coroutines support, Android database best practices, Room Query annotation, Android app development, Room Database tutorial, Room suspend functions, Android database optimization",
  },
  {
    id: 7,
    title: "Room Tutorial(Part-1):  Grasping the Fundamentals",
    date: Date.parse("June 5, 2024"),
    tags: ["Android", "Room Database"],
    description:
      "Learn to set up Room Database in Android. Explore its advantages over SQLite and implement a student database step-by-step.",
    path: "room-tutorial-part-1",
    slug: "/blogs/room-tutorial-part-1",
    social_image: "/assets/blogs/room-tutorial/social-image.jpg",
    keywords:
      "Room Database Android, Android local database, SQLite vs Room, Android ORM, Room persistence library, Android data storage, Room Database setup, Android database optimization, Room Database components, Android app development, Room Database tutorial, Kotlin Room implementation, Android data persistence, Room Database dependencies, Android database best practices",
  },
  {
    id: 6,
    title:
      "Understanding the Internal Workings of Suspend Functions in Kotlin Coroutines",
    date: Date.parse("May 5, 2024"),
    tags: ["Kotlin", "Coroutines"],
    description:
      "Let's delve into the internal workings of suspend functions and the magic they bring to asynchronous programming.",
    path: "internal-working-of-suspend-function",
    slug: "/blogs/internal-working-of-suspend-function",
    social_image: "",
    keywords:
      "Kotlin, kotlin coroutines, suspend function, internal working of suspend function, android, coroutines interview question",
  },
  {
    id: 5,
    title: "Code Block Styling with react-syntax-highlighter in Markdown",
    date: Date.parse("March 5, 2024"),
    tags: ["React", "Markdown"],
    description:
      "A Guide to use react-syntax-highlighter to customize code block styling.",
    path: "syntax-styling-using-react-syntax-highlighter",
    slug: "/blogs/syntax-styling-using-react-syntax-highlighter",
    social_image: "",
    keywords:
      "React, Markdown, Code block, Syntax Highlighter, react-syntax-highlighter, Web development, Custom styling, Code block customization, React integration, HTML tags, Tutorial, aayush, aayush chaudhary, thearchetypee",
  },
  {
    id: 4,
    title: "How to render Markdown in React using markdown-to-jsx?",
    date: Date.parse("March 2, 2024"),
    tags: ["React", "Markdown"],
    description:
      "A Guide to Using markdown-to-jsx for Rendering Markdown Files in React",
    path: "render-markdown-in-react",
    slug: "/blogs/render-markdown-in-react",
    social_image: "",
    keywords:
      "React, Markdown rendering, markdown-to-jsx, Web development, Custom styling, Code block, Web design, Learn markdown, React component, Blog website, Rendering libraries, Code block customization, React integration, HTML tags, Tutorial, aayush, aayush chaudhary, thearchetypee",
  },
  {
    id: 1,
    title: "Mastering Side Effects in Jetpack Compose",
    date: Date.parse("April 23, 2023"),
    description: "Full guide to Compose effect handlers.",
    tags: ["Android", "Jetpack Compose"],
    path: "compose-effect-handlers",
    slug: "/blogs/compose-effect-handlers",
    social_image: "",
    keywords:
      "Android, Jetpack Compose, Side Effects, Effect Handlers, aayush, aayush chaudhary, thearchetypee",
  },
  {
    id: 2,
    title: "Unleashing the Power of Kotlin Annotations",
    date: Date.parse("May 7, 2023"),
    tags: ["Kotlin"],
    description:
      "Discover the power of Kotlin annotations for concise, readable, and maintainable code, simplifying tasks and speeding up development.",
    path: "kotlin-annotations",
    slug: "/blogs/kotlin-annotations",
    social_image: "",
    keywords:
      "Kotlin, Annotations, Code Generation, aayush, aayush chaudhary, thearchetypee",
  },
  {
    id: 3,
    title:
      "Adapt Your Android App: Embrace Custom Back Press Handling in Android 13",
    date: Date.parse("October 14, 2023"),
    description: "Custom back press handling in Android 13.",
    tags: ["Android"],
    path: "back-press-handling-in-android-13",
    slug: "/blogs/back-press-handling-in-android-13",
    social_image:
      "/assets/blogs/back-press-handling-in-android-13/header-image.jpg",
    keywords:
      "Android, Back Press Handling, Android 13, Custom Back Press Handling, aayush, aayush chaudhary, thearchetypee",
  },
];

export default ALL_BLOGS;
